// third-party
// import { useEffect, useState } from 'react';
// import { decrypt } from 'utils/commonFunctions';
// import axios from 'axios';
import { FormattedMessage } from 'react-intl';

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// const iomURL = process.env.REACT_APP_IOM_URL;
// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined
};

interface Branch {
  name: string;
  code: string;
}
// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
// };
function ApplicationMenu(branches: Branch[]) {
  // Use the userRights and branches to generate menu items
  const appsRoutes = branches
    ? branches.map((branch) => ({
        id: branch.code.toUpperCase(),
        title: <FormattedMessage id={`branch.${branch.code.toUpperCase()}`} />,
        type: 'item',
        url: `/apps/${branch.code.toLowerCase()}`,
        icon: icons.BuildOutlined,
        target: false,
        rights: `ngtMLie${branch.code.toUpperCase()}`
      }))
    : [];
  const applications: NavItemType = {
    id: 'group-applications',
    icon: icons.AppstoreAddOutlined,
    type: 'group',
    children: [...appsRoutes]
  };
  return applications;
}

export default ApplicationMenu;
